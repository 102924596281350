/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery-1.10.2@1.10.2/jquery-1.10.2.min.js
 * - /npm/bootstrap@3.3.7/dist/js/bootstrap.min.js
 * - /npm/vanilla-lazyload@17.8.5/dist/lazyload.min.js
 * - /npm/clipboard@2.0.11/dist/clipboard.min.js
 * - /npm/iconify-icon@1.0.8/dist/iconify-icon.min.js
 * - /gh/sfkios/sfw38s_js@3.0.0-rc.2/dist/notify.min.js
 * - /gh/sfkios/sfw38s_js@3.0.0-rc.2/dist/all.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
